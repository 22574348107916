<template>
    <footer class="footer footer-center p-4 bg-base-300 text-base-content">
        <aside>
            <p>Copyright © {{ currentYear }} - All right reserved by Benimsaham</p>
        </aside>
    </footer>
</template>
<script setup lang="ts">
//get current year
const currentYear = new Date().getFullYear();
</script>